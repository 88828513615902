import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DateLib from "../../../inc/DateLib";
import openBookingPanel from "../../booking-panel/actions/openBookingPanel";
import BookingRowTookPlace from "../__row-took-place";
import UserEditModal from "../../users/__edit/users__edit-modal";
import './styles.css';

function getTarifData({order, stateList, type, userBalance}) {
    function parseCategory(tarifName) {
        switch (tarifName) {
            case 'Общий':
            case 'Спорт':
                return 'PRO'
            case 'Сотрудник':
                 return 'STAFF'
            default:
                return 'FIRST_TIMER'
        }
    }
    const product = (order && order.productId in stateList.products)
        ? stateList.products[order.productId] : null;
    const name = (type.slug === 'certificate')
        ? (order && order.certificate) ? order.certificate.code : ''
        : (type.slug === 'balance')
            ? (userBalance) ? userBalance.type : ''
            : (product && product.category) ? product.category.name : '';
    const category = parseCategory(name)
    return {name, category};
}

function getPaymentData({booking, stateList, type }) {
    let paymentContent = '';
    const order = stateList.orders[booking.orderId] || null;
    const paymentType = (order && stateList.paymentTypes[order.paymentTypeId]) || null;
    const paymentStatus = (order && stateList.paymentStatuses[order.paymentStatusId]) || 
        (booking.time && stateList.bookingTimeStatuses[booking.time.statusId]) || null;

    if (type.slug === 'booking') {
        paymentContent = `${(paymentStatus) ? paymentStatus.name : ''}
        ${(paymentType) ? paymentType.name : ''}
        ${(order) ? order.total : ''}`;
    } else if (type.slug === 'balance') {
        paymentContent = paymentStatus ? `${(paymentStatus.id == 1) ? 'Минуты в резерве': ( (paymentStatus.id > 1 && paymentStatus.id < 4) ? 'Минуты списаны' : 'Возврат минут')}` : '';
    }
    
    return paymentContent;
}
function getHistoryData(booking) {
    const createdAt = DateLib.formatterDateTime(booking.createdAt, 'ru') || null;
    let updatedAt = DateLib.formatterDateTime(booking.updatedAt, 'ru') || null;
    return `${createdAt}\n${updatedAt !== createdAt ? updatedAt : ""}`
}

function getActionEditItem({roles, type, booking, order, dispatch}) {
    if (roles.includes('admin') === false) return null;
    return () => {
        let params = {};
        if (type) {
            if (type.slug === 'certificate') {
                params.orderId = (booking.orderId) ? booking.orderId : null;
            } else if (type.slug === 'balance') {
                params.userBalanceId = (booking.time) ? booking.time.userBalanceId : null;
            } else if (type.slug === 'booking') {
                params.orderId = (booking.orderId) ? booking.orderId : null;
                params.productId = (order) ? order.productId : null;
                params.paymentTypeId = (order) ? order.paymentTypeId : null;
                params.paymentStatusId = (order) ? order.paymentStatusId : null;
            }
        }
        openBookingPanel({
            dispatch,
            bookingId: booking.id,
            typeId: booking.typeId,
            slotTimeId: booking.slotTimeId,
            date: booking.slotTimeDate,
            minutes: booking.minutes,
            comment: booking.comment,
            userId: booking.userId,
            ...params
        });
    };
}

export default function BookingRow({booking, isSlotTimeDateTime = false, slotTime, isPrevTimeSlotTime, maxMinutes}){
    const dispatch = useDispatch();
    const [isShowModalUser, setIsShowModalUser] = useState(false);
    const roles = useSelector((state) => state.profile.roles);
    const stateList = useSelector((state) => state.list);
    const bookingId = useSelector((state) => state.bookingPanel.bookingId);
    const user = (booking.userId in stateList.users) ? stateList.users[booking.userId] : null;
    const type = (booking.typeId in stateList.bookingTypes)? stateList.bookingTypes[booking.typeId] : null;
    const order = (booking.orderId in stateList.orders)? stateList.orders[booking.orderId] : null;
    const name = (user) ? user.surnameNM : '';
    const email = (user && roles.includes('admin')) ? user.email : '';
    const phone = (user && roles.includes('admin')) ? user.phone : '';
    const userBalance = (user && booking.time && booking.time.userBalanceId in user.userBalances)
        ? user.userBalances[booking.time.userBalanceId] : null;
    const tarifData = getTarifData({order, stateList, type, userBalance});
    const paymentData = getPaymentData({booking, stateList, type});
    const historyData = getHistoryData(booking);
    const bookingStatus = booking ? stateList?.bookingStatuses?.[booking?.statusId] : null;
    const actionEditItem = getActionEditItem({roles, type, booking, order, dispatch});
    const actionClickUserName = (user && roles.includes('admin')) ? () => setIsShowModalUser(true) : actionEditItem;
    const isSearch = useSelector((state) => state.pageBooking.isSearch);
    return <><div
        className={` ${isSearch ? 'grid-booking-content__bookings-row--search' : 'grid-booking-content__bookings-row'} bookings__row`
            +`${(bookingId && bookingId === booking.id) ? ' active' : ''}`
            +`${tarifData.category === 'FIRST_TIMER' ? ' first-timer' : ''}`
            +`${tarifData.category === 'STAFF' ? ' staff-time' : ''}`
            + (isPrevTimeSlotTime ? ' prev-time' : '')
            + `${roles.includes('staff') ? ' staff' : ''}`
        }
    >
        <div
            className="bookings__row-item sticky-booking-content__bookings-row-col-2 bookings__row-item_flex"
        >
            {roles.includes('admin') ? <BookingRowTookPlace booking={booking}/> : null}
            <div onClick={actionEditItem}>
            {booking.minutes ? booking.minutes : null}
            </div>
        </div>
        <div
            className="bookings__row-item bookings-row__booking-id"
            tooltip={historyData}
            onClick={actionEditItem}
        >{booking.id}</div>
        { roles.includes('admin')
            ? <>
                <div className="bookings__row-item" onClick={actionEditItem}>{(type) ? type.name : ''}</div>
                <div className="bookings__row-item">{tarifData.name}</div>
                <div className="bookings__row-item" onClick={actionClickUserName}>{name}</div>
                <div className="bookings__row-item" onClick={actionEditItem}>{email}</div>
                <div className="bookings__row-item" onClick={actionEditItem}>{phone}</div>
                <div className="bookings__row-item">{paymentData}</div>
                { isSearch ? <div className="bookings__row-item">{bookingStatus.name}</div> : null}
              </>
            : <div className="bookings__row-item" onClick={actionEditItem}>{name}</div>
        }
        <div className="bookings__row-item" onClick={actionEditItem}>{booking.comment}</div>
    </div>
    {user && user.id ?
        <UserEditModal
            userId={user.id}
            isOpenModal={isShowModalUser}
            closeModal={() => setIsShowModalUser(false)}
        />
    : null}
    </>
}

