import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";

import IconButton from '@mui/material/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import Button from '../../button';
import ModalList from '../../modal-list';
import ModalMessage from '../../modal-message';
import ModalUserBalancesOperation from "../__operation";
import ModalBookingTimes from "../../booking-times/__index/booking-times__index-modal";
import BookingsExport from "../../bookings/__export";
import UserBalancesExport from "../__export";
import ApiBooking from "./../../../api/ApiBooking";
import { loadUsers } from "../../../reducerApi/List";
import "./styles.css";

const apiBooking = new ApiBooking({});

export default function UserBalancesIndexModal({actionSelectUserBalance, actionSuccessCreate, actionSuccessUpdate, userId, userBalances, ...other}){
    const dispatch = useDispatch();
    const [depositUserBalance, setDepositUserBalance] = useState(null);
    const [writeOffUserBalance, setWriteOffUserBalance] = useState(null);
    const [downloadParams, setDownloadParams] = useState(null);
    const [historyBalance, setHistoryBalance] = useState(null);
    const user = useSelector((state) => (userId in state.list.users) ? state.list.users[userId] : null );
    const bookingBalanceTypes = useSelector((state) => state.list.bookingBalanceTypes);
    if(!userBalances) userBalances = (user) ? user.userBalances : [];
    let balanceTypeIds = Object.keys(userBalances).map(key => userBalances[key].typeId);
    let bookingBalanceTypesFilter = {};
    
    if(user){
        Object.keys(bookingBalanceTypes).forEach((key)=>{
            if(!balanceTypeIds.includes(parseInt(key)))
                bookingBalanceTypesFilter[key] = bookingBalanceTypes[key];
        });
    }
    const createUserBalance = async (userBalanceType) => {
        const result = await apiBooking.createUserBalance({
            typeId: userBalanceType.id,
            userId: user.id
        });
        if(result){
            loadUsers({dispatch, ids: [user.id]});
            if(actionSuccessCreate) actionSuccessCreate(result);
            
        }
    }
    if(!user) return <ModalMessage {...other} message="Пользователь не найден"/>
    return <>
    {downloadParams ? <BookingsExport params={downloadParams} close={()=>setDownloadParams(null)}/> : null}
    <ModalList
        {...other}
        id="user-balances"
        title="Балансы пользователя"
        headers={['Название', 'Баланс, мин','Резерв, мин', 'Доступно, мин', '','','','',]}
        list={[
            ...Object.keys(userBalances).map((key)=>{
                let userBalance = userBalances[key];
                let diff = userBalance.balance - userBalance.reserved;
                return {
                    click: (actionSelectUserBalance) ? () => {
                        actionSelectUserBalance(userBalance);
                    }: null,
                    columns: [
                        userBalance.type,
                        userBalance.balance ,
                        userBalance.reserved,
                        <div className={`${diff > 0 ? 'user-balances__diff--positive' : 'user-balances__diff--negative '} `}>{diff}</div>,
                        <Button 
                            variant="text"
                            size="small"
                            onClick={()=>setDepositUserBalance(userBalance)}
                        >Пополнить</Button>,
                        <Button 
                            variant="text"
                            size="small"
                            onClick={()=>setWriteOffUserBalance(userBalance)}
                        >Списать</Button>,
                        <IconButton 
                            aria-label="История"
                            onClick={()=>setHistoryBalance(userBalance)}
                        >
                            <HistoryIcon />
                        </IconButton>,
                        <BookingsExport 
                            params={{userBalanceId: userBalance.id}}
                        />,
                        <UserBalancesExport
                            params={{userBalanceId: userBalance.id}}
                        />
                    ],
                }
            }),
            ...Object.keys(bookingBalanceTypesFilter).map((key) => {
                let bookingBalanceType = bookingBalanceTypesFilter[key];
                return {
                    columns: [
                        bookingBalanceType.name,
                        <Button 
                            variant="text"
                            size="small"
                            onClick={()=>createUserBalance(bookingBalanceType)}
                        >Добавить</Button>,
                        '',
                        '',
                        '',
                        '',
                        '',
                        ''
                    ]
                };
            })
        ]}
    >
    </ModalList>
    {depositUserBalance? 
        <ModalUserBalancesOperation
            isOpenModal={(depositUserBalance) ? true : false}
            closeModal={() => setDepositUserBalance(null)}
            type="DEPOSIT"
            userBalance={depositUserBalance}
            actionSuccess={(result)=>{
                if(actionSuccessUpdate) actionSuccessUpdate(result);
                setDepositUserBalance(null);
            }}
        /> 
    : null}
    {writeOffUserBalance? 
        <ModalUserBalancesOperation
            isOpenModal={(writeOffUserBalance) ? true : false}
            closeModal={() => setWriteOffUserBalance(null)}
            type="WRITE_OFF"
            userBalance={writeOffUserBalance}
            actionSuccess={(result)=>{
                if(actionSuccessUpdate) actionSuccessUpdate(result);
                setWriteOffUserBalance(null);
            }}
        /> 
    : null}
    {historyBalance?
        <ModalBookingTimes
            isOpenModal={(historyBalance) ? true : false}
            closeModal={() => setHistoryBalance(null)}
            userBalance={historyBalance}
        />
    : null}
    </>
}